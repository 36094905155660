<template>
  <div id="blog-list">
    <cover />

    <div class="tags" ref="topRef">
      <div class="container blog-container">
        <h3>{{ t('CHU_DE_BAN_QUAN_TAM_LA_GI?') }}</h3>

        <div class="tags-wrapper">
          <div v-for="tag in tags" :key="tag.name" class="tag" :class="{ active: tag.active }" @click="toggleTag(tag)">{{ tag.name }}</div>
        </div>
      </div>
    </div>

    <div class="latest">
      <div class="container blog-container">
        <h2>{{ t('BAI_VIET_MOI') }}</h2>

        <div class="latest-item">
          <div class="row">
            <div class="col col-12 col-xs-12 col-md-6">
              <div class="thumbnail">
                <router-link :to="applyParams(PATHS.BLOG_CONTENT, latest.slug)">
                  <img :src="latest.thumbnailUrl || defaultThumb" :alt="latest.title" />
                </router-link>
              </div>
            </div>
            <div class="col col-12 col-xs-12 col-md-6">
              <div class="text-wrapper">
                <div class="text">
                  <div class="date">{{ moment(latest.createdAt).format('DD.MM.YYYY') }}</div>
                  <h3>
                    <router-link :to="applyParams(PATHS.BLOG_CONTENT, latest.slug)">{{ latest.title }}</router-link>
                  </h3>
                  <div class="desc">{{ latest.summary?.trim() }}…</div>
                  <div class="tags-wrapper">
                    <div v-for="tag in latest.tags" :key="tag.name" class="tag">{{ tag.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="articles-list">
      <div class="container blog-container">
        <div class="row">
          <div class="col col-12 col-xs-12 col-md-6 col-lg-8">
            <div class="row">
              <div class="col col-12 col-xs-12 col-lg-6 article-item-wrapper" v-for="(article, index) in articles" :key="article.id" v-show="index > 0">
                <div class="article-item">
                  <div class="thumbnail">
                    <router-link :to="applyParams(PATHS.BLOG_CONTENT, article.slug)">
                      <img :src="article.thumbnailUrl || defaultThumb" :alt="article.title" />
                    </router-link>
                  </div>
                  <div class="text-wrapper">
                    <div class="text">
                      <div class="date">{{ moment(article.createdAt).format('DD.MM.YYYY') }}</div>
                      <h3>
                        <router-link :to="applyParams(PATHS.BLOG_CONTENT, article.slug)">{{ article.title }}</router-link>
                      </h3>
                      <div class="desc">{{ getDescription(article.summary?.trim(), 120) }}</div>
                      <div class="tags-wrapper">
                        <div v-for="tag in article.tags" :key="tag.name" class="tag">{{ tag.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="load-more">
              <primary-button :text="t('XEM_THEM')" inverse @click="loadMore" v-show="hasMore" />
              <a :href="`blog/${queryStr}`" v-if="hasMore"/>
            </div>
          </div>
          <div class="col col-12 col-xs-12 col-md-6 col-lg-4">
            <div class="search-box">
              <p><strong>{{ t('TIM_KIEM') }}</strong></p>

              <form @submit="search">
                <text-input v-model:value="searchTerm" :placeholder="t('NHAP_TU_KHOA')">
                  <template #icon>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                      <path d="M12.7549 11.2549H11.9649L11.6849 10.9849C12.6649 9.84488 13.2549 8.36488 13.2549 6.75488C13.2549 3.16488 10.3449 0.254883 6.75488 0.254883C3.16488 0.254883 0.254883 3.16488 0.254883 6.75488C0.254883 10.3449 3.16488 13.2549 6.75488 13.2549C8.36488 13.2549 9.84488 12.6649 10.9849 11.6849L11.2549 11.9649V12.7549L16.2549 17.7449L17.7449 16.2549L12.7549 11.2549ZM6.75488 11.2549C4.26488 11.2549 2.25488 9.24488 2.25488 6.75488C2.25488 4.26488 4.26488 2.25488 6.75488 2.25488C9.24488 2.25488 11.2549 4.26488 11.2549 6.75488C11.2549 9.24488 9.24488 11.2549 6.75488 11.2549Z" fill="#8F8FA3"/>
                    </svg>
                  </template>
                </text-input>
              </form>

              <p><strong>{{ t('DANH_MUC') }}</strong></p>

              <ul class="tags-list">
                <li v-for="tag in tags" :key="tag.id" @click="toggleTag(tag)" :class="{ active: tag.active }">
                  <span class="icon">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 10L5.5 5L0.5 0V10Z" fill="#2E2E38"/>
                    </svg>
                  </span>
                  <span>{{ tag.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';
import { TweenMax } from 'gsap';
import qs from 'qs';

import TextInput from '@/components/TextInput';
import PrimaryButton from '@/components/PrimaryButton';

import { getDescription, applyParams } from '@/helpers';
import PATHS from '@/consts/paths';

import defaultThumb from '@/assets/imgs/about-us-cover-min.jpg';

import Cover from './Cover';

export default {
  name: 'BlogList',
  components: {
    Cover,
    TextInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const title = t('BLOG');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    const topRef = ref(null);
    const firstLoad = ref(true);

    const tags = ref([]);
    const filters = computed(() => store.state.blog.filters);
    const articles = computed(() => store.state.blog.items);
    const hasMore = computed(() => store.getters['blog/hasMore']);

    const latest = computed(() => (articles.value.length ? articles.value[0] : {}));

    const queryStr = computed(() => qs.stringify({ page: filters.value.page + 1, tags: filters.value.tags }, { arrayFormat: 'repeat', skipNulls: true }));

    const toggleTag = (tag) => {
      const i = tags.value.findIndex((item) => item.name === tag.name);

      if (i >= 0) {
        tags.value = [
          ...tags.value.slice(0, i),
          {
            ...tag,
            active: !tag.active
          },
          ...tags.value.slice(i + 1, tags.value.length)
        ];
      }

      store.dispatch('blog/applyFilters', {
        tags: tags.value.filter((item) => item.active).map((item) => item.id),
        page: 1
      });
    };

    const searchTerm = ref(filters.value.term);

    const search = (e) => {
      e.preventDefault();

      store.dispatch('blog/applyFilters', {
        term: searchTerm.value,
        page: 1
      });
    };

    const loadMore = () => {
      store.dispatch('blog/applyFilters', {
        page: filters.value.page + 1
      });
    };

    onMounted(async () => {
      await store.dispatch('blog/getTags');

      const { query } = route;

      let queryTags = query.tags || [];
      if (typeof queryTags === 'string') {
        queryTags = [queryTags];
      }

      tags.value = store.state.blog.tags.map((item) => ({
        ...item,
        active: queryTags?.includes(item.slug) || false
      }));

      store.dispatch('blog/applyFilters', {
        term: query.term || undefined,
        page: query.page || 1,
        tags: queryTags?.map((item) => (tags.value.find((i) => i.slug === item)?.id)) || []
      });
    });

    watch(filters, (val) => {
      searchTerm.value = val.term;
      store.dispatch('blog/getArticles');

      if (!firstLoad.value) {
        if (val.page === 1) {
          TweenMax.to(window, .3, {
            scrollTo: topRef.value.offsetTop
          });
        }
      }

      router.replace({ query: {
        page: val.page > 1 ? val.page : undefined,
        tags: val.tags.map((item) => (tags.value.find((i) => i.id === item))?.slug),
        term: val.term || undefined
      } });

      firstLoad.value = false;
    });

    return {
      t,
      tags,
      toggleTag,
      articles,
      latest,
      moment,
      getDescription,
      searchTerm,
      search,
      defaultThumb,
      topRef,
      hasMore,
      loadMore,
      applyParams,
      PATHS,
      queryStr
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#blog-list {
  width: 100%;
  padding-bottom: 80px;

  .blog-container {
    max-width: 960px;

    h2 {
      font-weight: 700;
      font-size: 40px;
      color: $geranium;
      margin-bottom: 20px;
    }
  }

  .tags {
    padding: 40px 0;

    h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  .tags-wrapper {
    .tag {
      display: inline-block;
      border-radius: 24px;
      border: 1px solid $spanishPink;
      color: $lava;
      padding: 8px 24px;
      text-transform: capitalize;
      margin-right: 15px;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover, &.active {
        background-color: $lava;
        color: $white;
      }
    }
  }

  .latest-item {
    margin-bottom: 30px;

    .thumbnail {
      overflow: hidden;
      padding-bottom: 60%;
      position: relative;

      @media (max-width: 767px) {
        margin-bottom: 60%;
        margin-left: -15px;
        margint-right: -15px;
        width: calc(100% + 30px);
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 12px;

        @media (max-width: 767px) {
          border-radius: 0;
        }
      }
    }

    .text-wrapper {
      position: relative;
      height: 100%;

      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 15px;

        @media (max-width: 767px) {
          bottom: 20px;
        }

        h3 {
          font-weight: 700;
          font-size: 16px;
        }

        .desc {
          margin-bottom: 20px;
        }

        .tags-wrapper {
          .tag {
            color: $raisin;
            border: 0;
            background-color: $honeyDew;
            font-size: 12px;
            padding-top: 5px;
          }
        }

        .date {
          color: $grey;
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .article-item {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 12px;

    @media (max-width: 767px) {
      margin-left: -15px;
      margint-right: -15px;
      width: calc(100% + 30px);
      border-radius: 0;
    }

    .thumbnail {
      border-radius: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
      padding-bottom: 60%;
      position: relative;

      @media (max-width: 767px) {
        border-radius: 0;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .text-wrapper {
      margin-top: 15px;
      padding: 15px;

      .text {
        padding-bottom: 15px;

        h3 {
          font-weight: 700;
          font-size: 16px;
          height: 50px;
        }

        .desc {
          margin-bottom: 20px;
        }

        .tags-wrapper {
          .tag {
            color: $raisin;
            border: 0;
            background-color: $honeyDew;
            font-size: 12px;
            padding-top: 5px;
          }
        }

        .date {
          color: $grey;
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .load-more {
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .search-box {
    background-color: $snow;
    border-radius: 12px;
    padding: 15px;
    padding-bottom: 20px;

    form {
      margin-bottom: 20px;
    }

    .text-input {
      width: 100%;

      input {
        font-size: 12px;
      }
    }

    .tags-list {
      padding: 0;
      margin: 0;
      margin-top: 15px;
      list-style: none;

      li {
        padding: 7px 0;
        text-transform: capitalize;
        cursor: pointer;
        transition: all .2s ease-in-out;

        .icon {
          margin-right: 15px;

          svg {
            path, polygon {
              fill: currentColor;
            }
          }
        }

        &:hover, &.active {
          color: $geranium;
        }
      }
    }
  }
}
</style>
