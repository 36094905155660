<template>
  <footer :class="{ dark }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="logo-holder">
            <logo />
          </div>
          <logo-text />
        </div>
        <div class="col-12 col-lg-4">
          <div class="row nav-menu">
            <div class="col-6 col-lg-6 menu-item" v-for="menu in menuItems" :key="menu.text">
              <router-link :to="menu.path" v-if="menu.path">{{ t(menu.text) }}</router-link>
              <a :href="menu.url" target="_self" v-if="menu.url">{{ t(menu.text) }}</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
        </div>
      </div>
    </div>

    <div class="floating-bar" ref="floatingBarRef">
      <div class="container">
        <a href="tel:18006246"><div class="hotline">
          <img :src="phoneIcon" :alt="hotlineNumber"/>
          <span>{{ hotlineNumber }}</span>
        </div></a>

        <router-link :to="PATHS.SUBMIT_ORDER">
          <primary-button :text="t('CAM_NGAY!')" v-show="isDesktop" />
        </router-link>

        <messenger-plugin />
      </div>
    </div>

    <div class="promo-popup">
      <div class="modal fade" tabindex="-1" ref="promoPopupRef">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <router-link :to="promoRoute" @click="closePromoPopup">
                <img :src="promoImg" alt="Camdonhanh Promo" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';
import { useRoute } from 'vue-router';

import PATHS from '@/consts/paths';

import Logo from '@/components/Logo';
import LogoText from '@/components/LogoText';
import PrimaryButton from '@/components/PrimaryButton';
import MessengerPlugin from '@/components/MessengerPlugin';

import phoneIcon from '@/assets/imgs/phone-icon-min.png';
import promoImg from '@/assets/imgs/promos/promo-2025-tet.jpg';

const hotlineNumber = '1800 6246';

const menuItems = [
  {
    text: 'HOME',
    path: PATHS.HOME
  },
  {
    text: 'ABOUT_US',
    path: PATHS.ABOUT
  },
  {
    text: 'CONTACTS',
    path: PATHS.CONTACTS
  },
  {
    text: 'BLOG',
    // path: PATHS.BLOG
    url: 'https://camdonhanh.vn/blog'
  },
];

const promoRoute = '/blog/khuyen-mai-on-tet-2025';

export default {
  name: 'Footer',
  components: {
    Logo,
    LogoText,
    PrimaryButton,
    MessengerPlugin
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    const routePath = computed(() => route.path);

    const floatingBarRef = ref(null);

    const promoPopupRef = ref(null);
    const promoPopupModal = ref(null);

    const isDesktop = computed(() => store.state.app.isDesktop);

    const watchScroll = () => {
      const y = window.scrollY;
      const floatingFooterHeight = 80;

      if (y > floatingFooterHeight) {
        floatingBarRef.value.classList.add('passed-view-port');
      } else {
        floatingBarRef.value.classList.remove('passed-view-port');
      }
    };

    const showPromoPopup = () => {
      if (routePath.value === promoRoute) {
        return;
      }

      promoPopupModal.value.show();
    };

    const closePromoPopup = () => {
      promoPopupModal.value.hide();
    };

    onMounted(() => {
      window.addEventListener('scroll', watchScroll);

      if (routePath.value !== promoRoute) {
        setTimeout(showPromoPopup, 3000);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', watchScroll);
    });

    watch(promoPopupRef, (elem) => {
      if (!elem) return;

      promoPopupModal.value = new Modal(elem);
    });

    return {
      t,
      menuItems,
      phoneIcon,
      hotlineNumber,
      floatingBarRef,
      isDesktop,
      PATHS,
      promoPopupRef,
      promoImg,
      closePromoPopup,
      promoRoute
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

footer {
  text-align: left;
  color: $gainsboro;
  font-size: 12px;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: $independence;
  margin-bottom: $floatingFooterHeight;

  @media (max-width: 767px) {
    margin-bottom: $floatingFooterHeightMobile;
    text-align: center;
  }

  .promo-popup {
    img {
      width: 100%;
    }
  }

  .logo-holder {
    width: 45px;
    height: 45px;
    background-color: $geranium;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;

    .logo {
      color: $white;
      width: 24px;
      margin-top: 12px;
      margin-left: 1px;
    }

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;

      .logo {
        margin-top: 9px;
      }
    }
  }

  .nav-menu {
    .menu-item {
      font-size: 16px;
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }

  .floating-bar {
    background: rgba(225, 31, 39, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 0;
    padding-top: 0;
    box-sizing: border-box;
    transition: all .2s ease-in-out;
    z-index: 9;

    &.passed-view-port {
      padding-top: 12px;
      height: $floatingFooterHeight;

      @media (max-width: 767px) {
        height: $floatingFooterHeightMobile;
        padding-top: 9px;
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
    }

    .hotline {
      img {
        width: 56px;
        height: 56px;
        object-fit: contain;
        vertical-align: middle;
        margin-right: 10px;

        @media (max-width: 767px) {
          width: 42px;
          height: 42px;
        }
      }

      span {
        vertical-align: middle;
        font-weight: 700;
        color: $white;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
